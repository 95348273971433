import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import PostListing from "../components/PostListing"
import SEO from "../components/SEO/SEO"
import config from "../../data/SiteConfig"

function Home({ data }) {
  const postList = []
  data.allMarkdownRemark.edges.forEach(postEdge => {
    postList.push({
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      cover: postEdge.node.frontmatter.cover,
      title: postEdge.node.frontmatter.title,
      date: postEdge.node.fields.date,
      excerpt: postEdge.node.excerpt,
      timeToRead: postEdge.node.timeToRead,
    })
  })
  return (
    <>
      <Helmet title={config.siteTitle} />
      <SEO />
      <PostListing postList={postList} />
    </>
  )
}

export default Home

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query HomeQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/articles/" } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`
